<template>
  <div>
    <div class="mx-auto my-5 text-center" v-if="!loaded">
      <b-spinner large label="Large Spinner"/>
      <div>Loading data</div>
    </div>
    <p class="font-weight-bold text-center" v-else-if="items.length === 0">Sorry no device has been connected</p>
    <div v-else>
      <table class="table table-striped table-bordered bg-white">
        <thead>
        <tr>
          <th>ID</th>
          <th>Type</th>
          <th>Model</th>
          <th>Date Created</th>
          <th>Date Modified</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>{{ item.device_id }}</td>
          <td>{{ item.device_type }}</td>
          <td>{{ item.device_model }}</td>
          <td>{{ date(item.date_created) }}</td>
          <td>{{ date(item.date_modified) }}</td>
          <td>
            <button type="button" class="btn btn-outline-primary mr-3" @click="openPushNotificationHistoryModal(item.push_notification_history)">Push History</button>
            <button type="button" class="btn btn-outline-danger" @click="removeToken(index)"><i class="fa-solid fa-trash-can"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center mt-4">
        <Pagination v-if="pagination.total > pagination.per_page" :per-page="pagination.per_page" :current-page="pagination.page" :total="pagination.total"
                    v-on:paginationUpdate="getPushNotification"/>
      </div>
    </div>

    <b-modal id="push-notification-history-modal"
             title="Push Notification History"
             title-class="font-weight-bold w-100"
             size="xl"
             :ok-only="true"
             scrollable
    >
      <div class="m-4">
        <p class="font-weight-bold text-center" v-if="pushHistory.length === 0">Sorry no push notification has been sent to this token</p>
        <table v-else class="table table-striped table-bordered bg-white">
          <thead>
          <tr>
            <th>Title</th>
            <th>Status</th>
            <th>Date Created</th>
            <th>Date Modified</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in pushHistory" :key="'history-' + index">
            <td>{{ item.title }}</td>
            <td class="text-center">{{ getStatusWording(item.status) }}</td>
            <td>{{ date(item.date_created) }}</td>
            <td>{{ date(item.date_modified) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '../../../apiService'
import Pagination from '@/components/Global/Pagination.vue'

export default {
  name: 'AdminDevices',
  components: { Pagination },
  props: {
    hash: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      items: [],
      pushHistory: [],
      pagination: {
        per_page: 10,
        page: 1,
        total: 0
      }
    }
  },
  mounted () {
    this.getPushNotification(1)
  },
  methods: {
    async getPushNotification (page = 1) {
      this.loaded = false
      await ApiService.getAdminPushNotification(this.hash, page).then((resp) => {
        this.pagination = resp.data.data.pagination
        this.items = resp.data.data.results
        this.loaded = true
      })
    },
    openPushNotificationHistoryModal (history) {
      this.pushHistory = history
      this.$bvModal.show('push-notification-history-modal')
    },
    removeToken (index) {
      this.$swal({
        title: 'Are you sure?',
        text: 'This will remove the token for this user',
        type: 'warning',
        showCancelButton: true,
        preConfirm: (result) => {
          if (result === true) {
            ApiService.removeAdminDevice(this.hash, this.items[index].device_token).catch(() => {})
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.items.splice(index, 1)
        }
      })
    },
    date (date) {
      if (date === null) {
        return 'N/A'
      }
      const newDate = new Date(date)
      return newDate.toLocaleString()
    },
    getStatusWording (status) {
      if (status === 0) {
        return 'Pending'
      }
      if (status === 1) {
        return 'Success'
      }
      if (status === 2) {
        return 'Failed'
      }

      return 'Unknown'
    }
  }
}
</script>
